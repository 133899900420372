<template>
  <div v-if="auction.type === 'live' && auction.start_date" class="ta-auction-active-date">
    <template v-if="datetime">{{ auction.start_date | datetime }}</template>
    <template v-else>{{ auction.start_date | date }}</template>
  </div>
  <div v-else-if="auction.type === 'timed' && auction.start_date && auction.end_date" class="ta-auction-active-date">
    <template v-if="datetime">{{ auction.end_date | datetime }}</template>
    <template v-else>{{ auction.end_date | date }}</template>
  </div>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionActiveDate",
  props: {
    label: {
      type: [Boolean, String],
      default() {
        return this.$t('Start Date')
      }
    },
    datetime: {
      type: Boolean,
      default: true
    }
  },
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
  .ta-auction-active-date {
    margin: 0 0.5rem;
  }
</style>
