<template>
  <transition name="modal">
    <div
      v-if="imgIndex !== null"
      class="vgs"
    >
      <div v-if="loading" class="vgs-img-loading-wrapper">
        <b-spinner variant="light"/>
      </div>
      <button
        type="button"
        class="ta-btn-square btn-light vgs__close"
        @click="close"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>

      </button>
      <button
        v-if="isMultiple"
        type="button"
        class="ta-btn-square btn-light vgs__prev"
        @click.stop="onPrev"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
        </svg>

      </button>
      <div
        v-if="images"
        class="vgs__container"
      >
        <VueImageZoomer :regular="imageUrl"
                        :zoom="imageUrl"
                        :click-zoom="true"
                        img-class="vgs__container__img"
                        @regularLoaded="onImgLoad"
                        :alt="alt"/>
        <!--                <img-->
        <!--                  ref="img"-->
        <!--                  class="vgs__container__img"-->
        <!--                  :style="{left: imgLeft+'px', top: imgTop + 'px'}"-->
        <!--                  :src="imageUrl"-->
        <!--                  :alt="alt"-->
        <!--                  @load="onImgLoad"-->
        <!--                >-->
        <slot></slot>
      </div>
      <button
        v-if="isMultiple"
        type="button"
        class="ta-btn-square btn-light vgs__next"
        @click.stop="onNext"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"/>
        </svg>

      </button>
      <!--      <div-->
      <!--        v-if="isMultiple"-->
      <!--        ref="gallery"-->
      <!--        class="vgs__gallery"-->
      <!--      >-->
      <!--        <div-->
      <!--          v-if="images"-->
      <!--          class="vgs__gallery__title"-->
      <!--        >-->
      <!--          {{ imgIndex + 1 }} / {{ images.length }}-->
      <!--        </div>-->
      <!--        <div-->
      <!--          v-if="images"-->
      <!--          class="vgs__gallery__container"-->
      <!--          :style="{ transform: 'translate(' + galleryXPos + 'px, 0)' }"-->
      <!--        >-->
      <!--          <img-->
      <!--            v-for="(img, i) in images"-->
      <!--            :key="i"-->
      <!--            class="vgs__gallery__container__img"-->
      <!--            :src="typeof img === 'string' ? img : img.url"-->
      <!--            :class="{ 'vgs__gallery__container__img&#45;&#45;active': i === imgIndex}"-->
      <!--            :alt="typeof img === 'string' ? '' : img.alt"-->
      <!--            @click.stop="onClickThumb(img, i)"-->
      <!--          >-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </transition>
</template>

<script>
import VueImageZoomer from "../image-zoomer/VueImageZoomer.vue";

export default {
  components: {VueImageZoomer},
  props: {
    images: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      drag: false,
      imgLeft: 0,
      imgTop: 0,
      offsetX: 0,
      offsetY: 0,
      coordX: 0,
      coordY: 0,
      elWidth: 0,
      elHeight: 0,
      imgIndex: this.index,
      image: null,
      galleryXPos: 0,
      thumbnailWidth: 120,
      loading: false
    };
  },
  computed: {
    imageUrl() {
      const img = this.images[this.imgIndex];
      if (typeof img === "string") {
        return img;
      }
      return img.url;
    },
    alt() {
      const img = this.images[this.imgIndex];
      if (typeof img === "object") {
        return img.alt;
      }

      return "";
    },
    isMultiple() {
      return this.images.length > 1;
    }
  },
  watch: {
    index(val, prev) {
      this.imgIndex = val;

      // updateThumbails when popup
      if (prev == null && val != null) {
        this.$nextTick(() => {
          this.updateThumbails();
        });
      }
    },
    imgIndex: {
      immediate: true,
      handler() {
        if (this.imgIndex !== null) {
          console.log("Shown");
          this.show();
        }
      }
    }
  },
  methods: {
    onImgLoad() {
      this.loading = false;
      this.resetImg();
    },
    show() {
      window.addEventListener("keydown", this.onWindowKeyDown);
      window.addEventListener("resize", this.resetImg);
      this.$emit('show')
    },
    close() {
      const eventData = {
        imgIndex: this.imgIndex
      };
      this.imgIndex = null;

      window.removeEventListener('keydown', this.onWindowKeyDown)
      window.removeEventListener('resize', this.resetImg)
      this.$emit("close", eventData);
    },
    onPrev() {
      if (this.imgIndex === null) return;
      if (this.imgIndex > 0) {
        this.imgIndex--;
      } else {
        this.imgIndex = this.images.length - 1;
      }
      this.updateThumbails();
    },
    onNext() {
      this.loading = true;
      if (this.imgIndex === null) return;
      if (this.imgIndex < this.images.length - 1) {
        this.imgIndex++;
      } else {
        this.imgIndex = 0;
      }
      this.updateThumbails();
    },
    onClickThumb(image, index) {
      this.imgIndex = index;
      this.updateThumbails();
    },
    updateThumbails() {
      if (!this.$refs.gallery) {
        return;
      }

      const galleryWidth = this.$refs.gallery.clientWidth;
      const currThumbsWidth = this.imgIndex * this.thumbnailWidth;
      const maxThumbsWidth = this.images.length * this.thumbnailWidth;
      const centerPos =
        Math.floor(galleryWidth / (this.thumbnailWidth * 2)) *
        this.thumbnailWidth;

      // Prevent scrolling of images if not needed
      if (maxThumbsWidth < galleryWidth) {
        return;
      }

      if (currThumbsWidth < centerPos) {
        this.galleryXPos = 0;
      } else if (
        currThumbsWidth >
        this.images.length * this.thumbnailWidth - galleryWidth + centerPos
      ) {
        this.galleryXPos = -(
          this.images.length * this.thumbnailWidth -
          galleryWidth -
          20
        );
      } else {
        this.galleryXPos = -(this.imgIndex * this.thumbnailWidth) + centerPos;
      }
    },
    resetImg() {
      if (!this.$refs.img) {
        return;
      }
      this.elWidth = parseInt(this.$refs.img.offsetWidth)
      this.elHeight = parseInt(this.$refs.img.offsetHeight)
      this.imgLeft = (window.innerWidth - this.elWidth) / 2;
      this.imgTop = (window.innerHeight - this.elHeight) / 2;
    },
    onWindowKeyDown(e) {
      if (e.keyCode === 37) {
        this.onPrev();
      } else if (e.keyCode === 39) {
        this.onNext();
      } else if (e.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$black-alpha-80: rgba(0, 0, 0, 0.8);
$black: #000;
$white: #fff;
$radius-medium: 8px;
$radius-large: 12px;
// Breakpoints
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
@mixin respond-to($media) {
  @if $media==xs {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  } @else if $media==sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @content;
    }
  } @else if $media==md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @content;
    }
  } @else if $media==lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
}

@mixin modal-base() {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 9998;
}

@mixin modal-mask() {
  @include modal-base();
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  //min-height: 100%;
  max-height: 100vh;
  background-color: $black-alpha-80;
  display: block;
}

.vgs {
  @include modal-mask();

  &__close {
    color: #fff;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    border: none;
    font-size: 40px !important;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 999;

    &:focus {
      outline: 0;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    width: 50px;
    height: auto;
    z-index: 999;
    cursor: pointer;
    font-size: 60px !important;
    color: #fff;
    background-color: transparent;
    border: none;

    &:focus {
      outline: 0;
    }
  }

  &__prev {
    left: 1rem;
  }

  &__next {
    right: 1rem;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    //max-width: 100vh;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 100%;
    //border-radius: $radius-large;
    background-color: $black;
    @include respond-to(xs) {
      //width: 100%;
      //max-width: 100%;
      margin: 0;
      //top: 0;
      //left: 0;
      //right: 0;
      //border-radius: 0;
      height: 100%;
    }

    &__img {
      //position: absolute;
      width: auto;
      //left: 50%;
      //transform: translateX(-50%);
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.vgs__gallery {
  @include respond-to(xs) {
    display: none;
  }
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  bottom: 10px;
  margin: auto;
  max-width: 100vh;
  white-space: nowrap;
  left: 0.5rem;
  right: 0.5rem;

  &__title {
    color: $white;
    margin-bottom: 0.5rem;
  }

  &__container {
    overflow: visible;
    display: block;
    height: 100px;
    white-space: nowrap;
    transition: all 200ms ease-in-out;
    width: 100%;

    &__img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      display: inline-block;
      float: none;
      margin-right: 20px;
      cursor: pointer;
      opacity: 0.6;
      border-radius: $radius-medium;
    }

    &__img--active {
      width: 100px;
      display: inline-block;
      float: none;
      opacity: 1;
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.vgs-img-loading-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

/deep/ .vgs__container__img {
  max-width: 100vw;
  max-height: 100vh;
}
</style>
