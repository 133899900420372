<template>
  <input-widget
    id="idSellAnItemDepartmentInput"
    type="select"
    v-model="model"
    :rules="required ? 'required' : ''"
    :error="sellAnItemModal.errors.department_uuid"
    :options="departmentOptions"
    :label="$t('Department')"
    @change="onDepartmentChange"
  />
</template>

<script>
import {mapMutations, mapState} from "vuex";
import InputWidget from "@/components/core/InputWidget.vue";
import baseInputMixin from "@/components/sell-an-item-modal/baseInputMixin";

export default {
  name: "DepartmentInput",
  components: {InputWidget},
  mixins: [baseInputMixin('', 'department_uuid')],
  computed: {
    ...mapState([
      'departmentsForSellAnItem',
      'sellAnItemModal'
    ]),
    departmentOptions() {
      const placeholder = {value: "", text: this.$t('Please select department')};
      if (!this.departmentsForSellAnItem) {
        return [placeholder];
      }
      return [
        placeholder,
        ...this.departmentsForSellAnItem.map(department => ({
          value: department.key,
          text: this.$options.filters.currentLocale(department.name)
        }))
      ]
    },
  },
  methods: {
    ...mapMutations(['setSellAnItemModalFieldInput', 'resetSellAnItemErrors']),
    onDepartmentChange(val) {
      if (val)
        this.resetSellAnItemErrors('department_uuid')
      this.setSellAnItemModalFieldInput({field: 'department_uuid', value: val})
      this.setSellAnItemModalFieldInput({field: 'category_uuid', value: ''})
      this.setSellAnItemModalFieldInput({field: 'dynamicFields', value: {}})
    }
  }
}
</script>

<style scoped>

</style>
