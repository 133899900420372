<template>
  <div v-if="lot.currency" class="ta-lot-current-price">
    <template v-if="lot.status === 'sold'">
      <label v-if="winningBidLabel" class="ta-current-price-label">{{ winningBidLabel }}</label>
      <span class="ta-winning-bid-amount">
        {{ (lot.winning_bid ? lot.winning_bid.amount : lot.last_bid_amount) | asCurrency(lot.currency.code) }}
      </span>
    </template>
    <template v-else-if="lot.winning_bid || lot.last_bid_amount">
      <label v-if="currentPriceLabel" class="ta-current-price-label">{{ currentPriceLabel }}</label>
      <span class="ta-current-price-amount">
        {{ (lot.winning_bid ? lot.winning_bid.amount : lot.last_bid_amount) | asCurrency(lot.currency.code) }}
      </span>
    </template>
    <template v-else>
      <label v-if="startPriceLabel" class="ta-start-price-label">{{ startPriceLabel }}</label>
      <span class="ta-start-price-amount">
        {{ lot.start_price | asCurrency(lot.currency.code) }}
      </span>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "BiddingCurrentPrice",
  props: {
    startPriceLabel: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('Start Price')
      }
    },
    currentPriceLabel: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('Current Bid')
      }
    },
    winningBidLabel: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('Winning Bid')
      }
    }
  },
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style scoped lang="scss">
@import "../../../bootstrap/import";

.ta-lot-current-price {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ta-current-price-amount,
  .ta-start-price-amount,
  .ta-winning-bid-amount {
    color: $primary;
    font-weight: bold;
    font-size: $font-size-base;
  }
}
</style>
