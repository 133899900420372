<template>
  <div v-if="auctionCoverImg" class="ta-auction-view-cover">
    <img :src="auctionCoverImg" />
  </div>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionCover",
  mixins: [auctionViewMixin],
}
</script>

<style lang="scss" scoped>
.ta-auction-view-cover {
  margin-bottom: 1rem;

  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}
</style>
