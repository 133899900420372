<template>
  <div v-if="lot && lot.auction && mainConfig.enableBidding && (!(isLotSold || isLotUnsold) || isAfterSale)" class="ta-lot-bid-input-wrapper">
    <div v-if="lot.auction.type === 'timed'">
      <div v-if="!isOnline" class="ta-bidding-offline-wrapper">
        <div class="ta-bidding-offline">
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
            <path
              d="M16.71,16.29h0l-13-13A1,1,0,0,0,2.29,4.71L5.65,8.06a7,7,0,0,0-.59,2A4,4,0,0,0,6,18h9.59l2.7,2.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42ZM6,16a2,2,0,0,1,0-4,1,1,0,0,0,1-1,5,5,0,0,1,.2-1.39L13.59,16ZM18.42,8.22A7,7,0,0,0,12,4a6.74,6.74,0,0,0-2.32.4,1,1,0,0,0,.66,1.88A4.91,4.91,0,0,1,12,6a5,5,0,0,1,4.73,3.39,1,1,0,0,0,.78.67,3,3,0,0,1,1.85,4.79,1,1,0,0,0,.16,1.4,1,1,0,0,0,.62.22,1,1,0,0,0,.78-.38,5,5,0,0,0-2.5-7.87Z"/>
          </svg>
          {{
            $t(`You're offline.`)
          }}
        </div>
        <div>
          {{
            $t(`You will see updated activity on the lot once you are back online.`)
          }}
        </div>
      </div>
      <div v-else-if="showReloadMessage" class="ta-bidding-reloading-wrapper">
        <div>{{ $t('Your internet connection was just restored.') }}</div>
        <div>{{ $t('We will reload the page to make sure you have most recent data') }}</div>
        <div class="text-right">
          {{ $t('Reloading in {x} seconds', {x: reloadSeconds}) }}
        </div>
      </div>
      <div class="ta-lot-bid-input">
        <div v-if="biddingFinished && lot.status === 'published'" class="ta-bidding-please-wait">
          <b-spinner small class="mb-2" label="Spinning"></b-spinner>
          <div>{{ $t('Please wait...') }}</div>
        </div>
        <div v-if="!isOnline" class="ta-bidding-offline-overlay"></div>
        <register-as-bidder-button :auction="lot.auction" :block="true" />
        <slot></slot>
      </div>
    </div>
    <div v-else-if="lot.auction.type === 'live'">
      <register-as-bidder-button :auction="lot.auction" :block="true" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";
import RegisterAsBidderButton from "@/views/AuctionViewPage/RegisterAsBidderButton.vue";

export default {
  name: "BiddingActions",
  components: {RegisterAsBidderButton},
  mixins: [lotBiddingMixin],
  data: () => ({
    showReloadMessage: false,
    reloadSeconds: 5,
    offlineStartTime: null
  }),
  computed: {
    ...mapState(['mainConfig', 'socket']),
    ...mapGetters(['isOnline'])
  },
  watch: {
    async isOnline(newVal, oldVal) {
      const goesOnline = newVal && !oldVal;
      const goesOffline = !newVal && oldVal;

      if (goesOnline) {
        // If you have been offline for only several seconds
        if (Date.now() - this.offlineStartTime > 15 * 1000) {
          this.showReloadMessage = true;
          this.reload();
        }
        this.offlineStartTime = null;
      } else if (goesOffline) {
        this.offlineStartTime = Date.now();
        this.showReloadMessage = false;
      }
    }
  },
  methods: {
    reload() {
      setInterval(() => {
        this.reloadSeconds--;
        if (this.reloadSeconds <= 0) {
          window.location.reload();
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-bidding-please-wait {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;

  .spinner-border-sm {
    min-height: 20px;
    min-width: 20px;
  }
}

.ta-lot-bid-input-wrapper {
  margin-top: 0.5rem;
}

.ta-lot-bid-input {
  position: relative;
}

.ta-bidding-offline-wrapper {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.ta-bidding-reloading-wrapper {
  background-color: #fff3cd;
  color: #856404;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 6px;
}

.ta-bidding-offline {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  > svg {
    margin-right: 1rem;
  }
}

.ta-bidding-offline-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.4);
  color: white
}

#artisioTimedAuctions {
  a.ta-click-to-reload {
    color: white;
  }
}
</style>
