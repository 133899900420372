import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState(["auctionObject", "mainConfig"]),
    auction() {
      return this.auctionObject.data;
    },
    auctionCoverImg() {
      if (!this.auction.images || !this.auction.images.length) {
        return null;
      }
      return this.auction.images[1].lg.url
    },
    viewingDates() {
      const startDate = this.$options.filters.date(this.auction.viewing_start_date)
      const endDate = this.$options.filters.date(this.auction.viewing_end_date)

      if (startDate !== endDate) {
        return `${this.$options.filters.datetime(this.auction.viewing_start_date)} - ${this.$options.filters.datetime(this.auction.viewing_end_date)}`
      }

      const startTime = this.$options.filters.time(this.auction.viewing_start_date)
      const endTime = this.$options.filters.time(this.auction.viewing_end_date)
      return `${startDate} | ${startTime} - ${endTime}`
    },
    locationInfo() {
      return this.$options.filters.currentLocale(this.auction.auction_location_info)
    },
    contactInfo() {
      return this.$options.filters.currentLocale(this.auction.contact_info)
    },
    viewingInfo() {
      return this.$options.filters.currentLocale(this.auction.viewing_info)
    }
  }
}
