<template>
  <div v-if="lot.currency && !(isLotSold || isLotUnsold)" class="ta-lot-view-max-bid">
    <b-modal
      id="max-bid-modal"
      ref="maxBidModal"
      :title="$t('Set your desired max bid amount')"
      :visible="maxBidModal"
      @show="onModalShow"
      @hide="onModalHide"
      @close="onModalClose"
      @cancel="onModalClose"
      static
      lazy
    >
      <form ref="maxBidForm" @submit.stop.prevent>

        <tax-rate-and-premiums :lot="lot" />

        <template v-if="mainConfig.showBidAmountsInConfirmation">
          <BidAmount :lot="lot" :bid-amount="parseFloat(maxBidAmount || 0)"/>
          <CalculatedPremium :lot="lot" :bid-amount="parseFloat(maxBidAmount || 0)"/>
          <BidTotalAmount :lot="lot" :bid-amount="parseFloat(maxBidAmount || 0)"/>
        </template>
        <hr>

        <b-form-group
          label-for="max-bid-amount"
          :invalid-feedback="errorMessage"
          :state="maxBidState"
          class="ta-lot-bidding-input"
        >
          <b-input-group :prepend="lot.currency.symbol">
            <b-form-input id="max-bid-amount"
                          type="number"
                          :state="maxBidState"
                          v-model="maxBidAmount"
                          :placeholder="$t('Your Max Bid')"
                          required
            />
          </b-input-group>
        </b-form-group>
        <upcoming-amount-popover
          target="max-bid-amount"
          :state-object="upcomingMaxBidPopover"
          :text="$t('Current Max bid amount is invalid. Please submit one of the following amounts') + ':'"
          :currency-code="lot.currency.code"
          :as-popover="false"
          @hidePopover="hideUpcomingAmountPopover('upcomingMaxBidPopover')"
          @setNewAmount="saveUpcomingMaxBidAmount"
        />
      </form>

      <template #modal-footer>
        <div>
          <b-button v-if="lot.max_bid" variant="danger" size="sm" @click="deleteMaxBidAmount">
            {{ $t('Delete') }}
          </b-button>
        </div>
        <div>
          <b-button variant="primary" size="sm" class="mx-2" @click="saveMaxBidAmount">
            {{ $t('Save') }}
          </b-button>
          <b-button variant="secondary" size="sm" @click="onModalClose">
            {{ $t('Close') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <div class="ta-lot-view-max-bid-inner">
      <div class="ta-lot-view-max-bid-wrapper">
        <a href="javascript:void(0)" id="change-max-bid-button"
           v-b-tooltip:hover
           @click="showMaxBidModal" class="btn-link text-sm">
          {{ lot.max_bid ? $t('Change your max bid') : $t('Set your max bid') }}
        </a>
        <small v-if="lot.max_bid" class="ta-lot-view-current-max-bid"> - {{ lot.max_bid | asCurrency(lot.currency.code) }}</small>
      </div>
      <small class="ta-lot-view-number-of-bids">{{ lot.num_of_bids || 0 }} {{ $t('bids') }}</small>
      <b-tooltip v-if="biddingFinished" target="change-max-bid-button" container="artisioTimedAuctions">
        {{ $t('Lot has ended') }}
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import maxBidMixin from "../../../mixins/maxBidMixin";
import {mapActions, mapState} from "vuex";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";
import UpcomingAmountPopover from "../../lot-view/UpcomingAmountPopover";
import {MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT} from "../../../constants";
import TaxRateAndPremiums from "@/components/TaxRateAndPremiums";
import {maxBidDelete, maxBidModalClose, maxBidSave} from "@/triggers";
import CalculatedPremium from "@/components/lot-view/CalculatedPremium.vue";
import BidAmount from "@/components/lot-view/BidAmount.vue";
import BidTotalAmount from "@/components/lot-view/BidTotalAmount.vue";

export default {
  name: "BiddingMaxBid",
  components: {BidTotalAmount, BidAmount, CalculatedPremium, TaxRateAndPremiums, UpcomingAmountPopover},
  mixins: [maxBidMixin, lotBiddingMixin],
  data: () => ({
    maxBidAmount: null,
    maxBidState: null,
    errorMessage: null,
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      maxBidModal: state => state.lotObject.maxBidModal
    })
  },
  methods: {
    ...mapActions(['createMaxBid', 'deleteMaxBid']),
    onModalShow() {
      this.errorMessage = '';
      this.maxBidState = null;
      this.maxBidAmount = this.lot.max_bid;
    },
    onModalHide() {
      this.setShowMaxBidModal(false)
    },
    onModalClose() {
      this.onModalHide()
      maxBidModalClose(this.title)
    },

    saveUpcomingMaxBidAmount(amount) {
      this.errorMessage = "";
      this.maxBidState = null;
      this.maxBidAmount = amount;
      this.saveMaxBidAmount();
    },

    async saveMaxBidAmount() {
      maxBidSave(this.title)
      this.hideUpcomingAmountPopover('upcomingMaxBidPopover');
      let maxBid = Number(this.maxBidAmount);
      let valid = true;

      if (maxBid < this.next) {
        valid = false;
        this.errorMessage = this.$t('Max bid amount must NOT be less than {amount}', {amount: this.$options.filters.asCurrency(this.next, this.lot.currency.code)})
      }

      if (this.settings.default_max_bidding_type === MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT && valid) {
        valid = await this.validateUpcomingAmount({
          newAmount: this.maxBidAmount,
          currentAmount: this.lot.winning_bid?.amount,
          startPrice: this.lot.start_price,
          increments: this.lot.auction.increments,
          stateObjectName: "upcomingMaxBidPopover",
        });
      }

      this.maxBidState = valid;

      if (valid) {
        this.createMaxBid({
          lot_uuid: this.lot.uuid,
          amount: maxBid
        });
        this.$refs.maxBidModal.hide();
      }
    },
    async deleteMaxBidAmount() {
      maxBidDelete(this.title)
      const status = await this.deleteMaxBid({uuid: this.lot.uuid})
      if (status === 200) {
        this.$refs.maxBidModal.hide();
      }
    }
  }
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  #max-bid-modal {
    .modal-footer {
      justify-content: space-between;
    }
  }
  .ta-lot-view-max-bid-inner {
    display: flex;
    justify-content: space-between;
  }
  .ta-lot-view-max-bid-wrapper {
    display: flex;
    align-items: center;
  }
  .ta-lot-view-current-max-bid{
    margin-left: 0.25rem;
  }
}
</style>
