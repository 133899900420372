import TEMPLATES from './templates'

const DEFAULT_CONFIG = {
  /**
   * Your unique Client ID
   */
  clientId: null,

  /**
   * The language in which the UI should be displayed
   */
  lang: "en",

  /**
   * The default page that should be displayed when opening online auctions page
   * and bidding app starts working.
   *
   * Available options
   * /home
   * /auctions
   * /lots
   * /private-treaty/auctions
   * /private-treaty/lots
   * /favorite-auctions
   * /favorite-lots
   * /invoices
   * /settlements
   * /items
   * /temporary-items
   * /profile
   */
  defaultPage: "/home",

  /**
   * The URL on which user will be redirected when clicking on login buttons/links
   */
  loginUrl: null,

  /**
   * Javascript function which will be executed when login button is clicked.
   * You must provide either `loginUrl` or `loginHandler`
   * If you provide both both will work.
   */
  loginHandler: null,

  /**
   * The URL on which user will be redirected when clicking on signup buttons/links
   */
  signupUrl: null,

  /**
   * Javascript function which will be executed when signup button is clicked.
   * You must provide either `signupUrl` or `signupHandler`
   * If you provide both both will work.
   */
  signupHandler: null,

  /**
   * Links which will be displayed bellow bidding section.
   * Each link in this array must be object containing the following properties.
   *
   * text     - Required - The actual text of the link.
   * url      - Optional - URL which should be opened
   * target   - Optional - html "target" attribute: "_self", "_blank", etc...
   * handler  - Optional - JavaScript function which will be executed when link is clicked.
   *
   * Typical examples:
   * [
   *   {
   *     url: "/shipping-details",
   *     text: "Shipping Details"
   *   },
   *   {
   *     text: "Terms and Conditions",
   *     handler: function(){
   *       // TODO Show modal with Terms and conditions
   *     }
   *   }
   * ]
   */
  links: [],

  /**
   * The URL which must return translations on all languages.
   * If you plan to have only English language you can skip this or set it to an empty string.
   * Response Example:
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   */
  translationsUrl: null,

  /**
   * Translations object
   *
   * Example:
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   */
  translations: {},

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Which social share icons to be displayed.
   * This can be either boolean or array
   * boolean true   - All supported social sharing buttons will be displayed
   * boolean false  - None of the social sharing buttons will be displayed
   * array          - Only those buttons will be displayed
   * Available social sharing buttons: 'email', 'facebook', 'twitter', 'linkedin'
   *
   * Example: `socialShares: ['facebook', 'email', 'twitter']`
   */
  socialShares: false,

  /**
   * To show print button or not.
   * This can be either boolean or function
   * boolean true    - The button will be displayed and default printing behavior will happen
   * boolean false   - The button will be hidden
   * Function        - The button will be displayed and specified function will
   *                    be called
   */
  printButton: false,

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Whether to show "Jump to lot" input or not
   */
  jumpToLot: true,

  /**
   * Whether to show breadcrumbs or not.
   */
  showBreadcrumbs: true,

  /**
   * Whether to show "Previous", "Next" and "Back to list" buttons or not
   */
  showPrevNextList: true,

  /**
   * Boolean - To show latest 5 bids in lot view or not.
   */
  showLatestBids: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   */
  enableWatchlist: true,

  /**
   * Whether to show select box for page size or not
   */
  perPageSelect: true,

  /**
   * Whether to show authentication buttons or not
   */
  enableAuth: true,

  /**
   * Whether to enable bidding or not
   */
  enableBidding: true,

  /**
   * Whether to enable sidebar filters or not
   */
  enableFilters: true,

  /**
   * Whether to enable sorting options dropdown
   */
  enableSorting: true,

  /**
   * Whether to show countdown timer or not
   */
  showCountdown: true,

  /**
   * Whether to show description or lot in sidebar or not.
   * If you set it to false it will be displayed bellow images
   */
  descriptionInSidebar: false,

  /**
   * Whether to move share and print buttons in sidebar
   * If you set it to false they will be displayed bellow images
   */
  sharesInSidebar: false,

  /**
   * CSS Selector.
   * These elements will be visible when user is guest, but hidden when user is authorized
   */
  visibleForGuestsSelector: '.ta-visible-for-guest',

  /**
   * CSS Selector.
   * These elements will be visible when user is authorized, but hidden when user is guest
   */
  visibleForAuthSelector: '.ta-visible-for-auth',

  /**
   * Artisio SSO cookie name
   */
  artisioCookieName: 'artisio_timed_sso',

  /**
   * Whether to enable private treaty buttons like: "Make an offer" and "Make an Enquiry"
   */
  enablePrivateTreaty: false,

  /**
   * The email address which should be used for "Make an Enquiry" button for "mailto"
   */
  makeAnEnquiryEmail: '',

  /**
   * Artisio branch uuid for which you want to display content on website.
   * If you leave this null it will display content for all branches
   */
  branchUuid: null,

  /**
   * Whether to show collection information in lot listing an in lot inner page
   */
  showCollectionInfo: true,

  /**
   * Dynamic fields which must be rendered for lot description
   * Giving false here means that item's actual description should be rendered
   * Giving true here means that all dynamic fields should be iterated and rendered
   * In array you should give Artisio dynamic field names which will be used to render fields
   *
   * @type Array|Boolean
   */
  descriptionDynamicFields: false,

  /**
   * Display "Show More" button when rendering description
   */
  showMoreInDescription: false,

  /**
   * Hide department list from sidebar if department is selected
   */
  hideDepartmentsIfSelected: false,

  /**
   * Whether to always show categories or only show when department is selected
   */
  alwaysShowCategories: false,

  templates: {
    ...TEMPLATES
  },

  /**
   * The type how the pagination should be handled
   * Options: 'PAGINATION', 'LOAD_MORE', 'SCROLL'
   */
  paginationType: 'PAGINATION',

  /**
   * The default sort field for lots
   * Any valid lot field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  lotDefaultSortField: 'lot_no',

  /**
   * The default sort field for favorite lots
   * Any valid lot field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  favoriteLotDefaultSortField: 'end_date',

  /**
   * The default sort by for lots
   * Valid Options: asc, desc
   */
  lotDefaultSortBy: 'asc',

  /**
   * The default sort by for favorite lots
   * Valid Options: asc, desc
   */
  favoriteLotDefaultSortBy: 'asc',

  /**
   * The default sort field for auctions
   * Any valid auction field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  auctionDefaultSortField: 'end_date',

  /**
   * The default sort field for favorite auctions
   * Any valid auction field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  favoriteAuctionDefaultSortField: 'end_date',

  /**
   * The default sort by for auctions
   * Valid Options: asc, desc
   */
  auctionDefaultSortBy: 'asc',

  /**
   * The default sort by for favorite auctions
   * Valid Options: asc, desc
   */
  favoriteAuctionDefaultSortBy: 'asc',

  /**
   * Default limit for lot pagination
   * Options can be configured by option `perPageOptions`
   */
  lotPaginationLimit: 25,

  /**
   * Default limit for favorite lots pagination
   * Options can be configured by option `perPageOptions`
   */
  favoriteLotPaginationLimit: 25,

  /**
   * Default limit for auction pagination
   * Options can be configured by option `perPageOptions`
   */
  auctionPaginationLimit: 25,

  /**
   * Default limit for favorite auctions pagination
   * Options can be configured by option `perPageOptions`
   */
  favoriteAuctionPaginationLimit: 25,

  /**
   * Terms and Conditions HTML string.
   * If this and `termsAndConditionsUrl` both are set to false terms and conditions links will be hidden
   */
  termsAndConditionsHtml: false,

  /**
   * Terms and Conditions URL.
   * If this and `termsAndConditionsHtml` both are set to false terms and conditions links will be hidden.
   * If this and `termsAndConditionsHtml` both are set non-false value this will have higher priority
   */
  termsAndConditionsUrl: false,

  /**
   * Currency formatting special string.
   * {symbol} can be moved at the end of the formatting string or replaced with {code} to display currency code, instead of symbol
   * Whitespace can be inserted between {symbol} and {number:} to have visual separation on webapp
   * In {number:} "," acts as thousands separator. Following options are supported for a thousand separator: {
   *   Dot - "."
   *   Comma - ","
   *   Apostrophe - "‘"
   *   Space - " "
   * }
   * In {number:} "." acts as decimal separator. Following options are supported for a decimal separator: {
   *   Dot - "."
   *   Comma - ","
   * }
   * Example:
   * "{symbol}{number:000 000,00}" - $100 000,99
   * "{symbol}{number:000,000.00}" - $100,000.99
   * "{symbol}{number:000000,00}" - $100000,99
   * "{symbol}{number:000'000,00}" - $100'000,99
   * "{code} {number:000 000,00}" - USD 100 000,99
   * "{number:000 000,00} {code}" - 100 000,99 USD
   * "{number:000 000,00}{code}" - 100 000,99USD
   * "{number:000 000.00} {code}" - 100 000.99 USD
   * "{number:000 000.00}{code}" - 100 000.99USD
   * "€{number:000 000,00}" - €100 000,99
   */
  currencyFormat: '{symbol}{number:000,000.00}',

  /**
   * Default format how dates should be displayed in Web App
   */
  dateFormat: 'DD MMM YYYY',

  /**
   * Default format how datetimes should be displayed in Web App
   */
  datetimeFormat: 'DD MMM YYYY HH:mm',

  /**
   * Default format how datetimes with seconds should be displayed
   */
  datetimeFormatWithSeconds: 'DD MMM YYYY HH:mm:ss',

  /**
   * Default format how time should be displayed in Web App
   */
  timeFormat: 'HH:mm',

  /**
   * Default format how time with seconds should be displayed in Web App
   */
  timeWithSecondsFormat: 'HH:mm:ss',

  /**
   * When you change something in filters section whether it should auto apply filters or not.
   * If this is set to false "Apply Filters" button will be visible
   */
  autoApplyFilters: true,

  fullScreenButtonOnImage: false,

  dontOpenOnImageClick: false,

  backButtonOnImage: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   */
  enableNotifyMeOnLotStart: false,

  /**
   * The home page URL of the application.
   * If you leave this null it will use Webapp's default home page address
   */
  homeUrl: null,

  gtag: {
    config: {
      id: null
    }
  },

  /**
   * Available options to be displayed under "Lot Status" filter criteria for filtering lots
   * All available options are:
   *  - `all` - Will display all lots
   *  - `upcoming` - Will display only lots that are published, but start date is in the future
   *  - `open` - Will display only lots that are published and started
   *  - `published` - Will display only lots that are published
   *  - `sold` - Will display only sold lots
   *  - `unsold` - Will display only unsold lots
   *  - `passed` - Will display all sold and unsold lots
   *
   */
  filterLotStatusOptions: [
    'all',
    'published',
    'sold',
    'unsold',
  ],

  /**
   * Default lot status selected in "Lot Status" filter criteria for filtering lots
   */
  defaultLotStatus: 'published',

  /**
   * Per page dropdown options, that will be displayed in every per page dropdown
   */
  perPageOptions: [5, 10, 25, 50, 100],

  /**
   * Whether to show bidding limit for bidders or not.
   * Please consider that this only makes sense if bidding limits are configured and set for bidders,
   * Even if you set this true, but no bidding limits are configured nothing will be displayed
   */
  showBiddingLimit: true,

  /**
   * Whether to display personal identification fields in registration or not
   */
  personalIdentificationDetails: false,

  /**
   * Whether to display bank account fields in registration or not
   */
  bankAccountDetails: false,

  /**
   * Whether to show or not current reserve status
   */
  showReserveStatus: true,

  /**
   * Number of minutes before the live auction start date to display live auction bidding button
   */
  liveBiddingButtonShowTime: 15,

  /**
   * Number of minutes before the live auction start date to hide commission bid buttons
   */
  liveCommissionButtonHideTime: 15,

  /**
   * Whether to show or not auction type in auction listing or in auction inner page
   */
  showAuctionType: false,

  /**
   * Setting true here will display 3 main numbers in confirmation dialog when user tries to make a bid.
   *  - Bid Amount
   *  - Commission (Calculated based on premiums)
   *  - Total Amount (Bid Amount + Commission)
   */
  showBidAmountsInConfirmation: false,

  /**
   * By default filters are displayed staticly on the place where it is configured.
   * If you set this true, only filters button will be displayed and clicking on it will show filters overlay over content
   */
  overlayFilters: false,

  /**
   * Image thumbnail carousel position on lot view page
   * Possible options: 'left', 'bottom'
   */
  imageCarouselPosition: 'bottom',

  /**
   * Whether to show lots count when the value is 0 in auctions list.
   */
  showZeroLotCount: true,

  /**
   * Whether to show 'bidding is open' badge for auction in auctions list
   */
  showAuctionBiddingOpenBadge: true,

  /**
   * Whether to show Buy Now button and enable Buy now functionality on published auctions when bidding is in progress
   * Warning! This will not show buy now button on live auctions.
   */
  enableBuyNowOnPublishedAuction: false,

  /**
   * Activating this option is good idea only for those auction houses who have only single department
   */
  alwaysSelectFirstDepartment: false
};

export const STRING_OPTIONS = [
  "clientId",
  "lang",
  "defaultPage",
  "loginUrl",
  "signupUrl",
  "translationsUrl",
  "visibleForGuestsSelector",
  "visibleForAuthSelector",
  "artisioCookieName",
  "branchUuid",
  "paginationType",
  "lotDefaultSortField",
  "lotDefaultSortBy",
  "auctionDefaultSortField",
  "auctionDefaultSortBy",
  "lotPaginationLimit",
  "auctionPaginationLimit",
  'termsAndConditionsHtml',
  'termsAndConditionsUrl',
  "currencyFormat",
  "dateFormat",
  "timeFormat",
  "timeFormatWithSeconds",
  "datetimeFormat",
  "datetimeFormatWithSeconds",
  "makeAnEnquiryEmail",
  "homeUrl",
  "preset",
  "defaultLotStatus",
  "liveBiddingButtonShowTime",
  "liveCommissionButtonHideTime",
  "imageCarouselPosition"
];
export const BOOLEAN_OPTIONS = [
  "socialShares",
  "jumpToLot",
  "showBreadcrumbs",
  "showPrevNextList",
  "showLatestBids",
  "enableWatchlist",
  "perPageSelect",
  "enableAuth",
  "enableBidding",
  "enableFilters",
  "enableSorting",
  "showCountdown",
  "descriptionInSidebar",
  "showMoreInDescription",
  "sharesInSidebar",
  "enablePrivateTreaty",
  "showCollectionInfo",
  "hideDepartmentsIfSelected",
  "autoApplyFilters",
  "fullScreenButtonOnImage",
  "dontOpenOnImageClick",
  "backButtonOnImage",
  "enableNotifyMeOnLotStart",
  "showBiddingLimit",
  "personalIdentificationDetails",
  "bankAccountDetails",
  "alwaysShowCategories",
  "showReserveStatus",
  "showAuctionType",
  "showBidAmountsInConfirmation",
  "overlayFilters",
  "showZeroLotCount",
  "showAuctionBiddingOpenBadge",
  "enableBuyNowOnPublishedAuction",
  "alwaysSelectFirstDepartment"
];

export const OBJECT_OPTIONS = [
  'translations',
  'templates',
  'gtag',
  'filterLotStatusOptions',
  'perPageOptions'
];

export default DEFAULT_CONFIG;
