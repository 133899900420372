<template>
  <a ref="el" v-if="shouldBeDisplayed" @click="onClick($event)" :href="biddingUrl" target="_blank"
     class="btn btn-primary ta-lot-stream-bid-button mt-2" :class="block ? 'btn-block' : ''">
    {{ $t('Live Bidding on Stream Bid') }}
  </a>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";
import i18n from "@/i18n";
import moment from "moment/moment";

export default {
  name: "AuctionStreamBidButton",
  components: {},
  props: {
    auction: {
      type: Object,
      required: true
    },
    block: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    interval: null,
    difference: null,
    shouldBeDisplayed: null
  }),
  computed: {
    ...mapState({
      timeDiff: state => state.timeDiff,
      mainConfig: state => state.mainConfig
    }),
    biddingUrl() {
      return this.auction ? this.auction.bidding_url : ''
    },
    biddingUrlExpired() {
      const expireDate = this.auction.bidding_url_expire_date;

      let eventDate = typeof expireDate === "string" ? moment(expireDate).toDate().getTime() : expireDate.getTime();
      return moment(eventDate).add(this.timeDiff) < Date.now();
    }
  },
  methods: {
    ...mapActions(['generateBiddingUrl']),
    ...mapMutations(['showModalMsgBox']),
    async onClick(ev) {
      if (this.biddingUrlExpired) {
        ev.preventDefault()
        const {biddingUrl, error} = await this.generateBiddingUrl({auction_uuid: this.auction.uuid})

        // If bidding url does nto exist, or it is still expired
        if (!biddingUrl || this.biddingUrlExpired) {
          this.showModalMsgBox({
            title: i18n.t('Error'),
            content: i18n.t(error || 'Bidding is not started yet.')
          });
        } else {
          this.$refs.el.click()
        }
      }
    },
    updateTimeDifference() {
      let startDate = this.auction.start_date;

      let eventDate = typeof startDate === "string" ? moment(startDate).toDate().getTime() : startDate.getTime();
      startDate = moment(eventDate).add(this.timeDiff);

      this.difference = startDate - Date.now()

      this.shouldBeDisplayed = this.biddingUrl && this.auction.is_approved_bidder && this.difference <= this.mainConfig.liveBiddingButtonShowTime * 60 * 1000 && this.auction.status !== 'completed'
    },

  },
  mounted() {
    this.updateTimeDifference();
    const interval = 1000;

    this.interval = setInterval(() => {
      this.updateTimeDifference();
    }, interval);
  },
  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";

</style>
