<template>
  <div v-if="auction.type === 'live' && auction.start_date" class="ta-auction-dates">
    <slot name="icon">
      <b-icon-calendar-date class="mr-1"/>
    </slot>
    <b class="ta-auction-dates-label">{{ $t('Sale Dates') }}: </b>
    <span class="ta-auction-dates-value">
      <template v-if="datetime">{{ auction.start_date | datetime }}</template>
      <template v-else>{{ auction.start_date | date }}</template>
    </span>
  </div>
  <div v-else-if="auction.type === 'timed' && auction.start_date && auction.end_date" class="ta-auction-dates">
    <slot name="icon">
      <b-icon-calendar-date class="mr-1"/>
    </slot>
    <b class="ta-auction-dates-label">{{ $t('Sale Dates') }}: </b>
    <span class="ta-auction-dates-value">
      <template v-if="datetime">{{ auction.start_date | datetime }}</template>
      <template v-else>{{ auction.start_date | date }}</template>
      -
      <template v-if="datetime">{{ auction.end_date | datetime }}</template>
      <template v-else>{{ auction.end_date | date }}</template>
    </span>
  </div>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionDates",
  mixins: [auctionViewMixin],
  props: {
    datetime: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped lang="scss">
.ta-auction-dates {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.ta-auction-dates-value {
  margin: 0 0.5rem;
}
</style>
