<template>
  <RegisterAsBidderButton :auction="auction" :block="block" class="mb-3"/>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import store from "@/store";
import i18n from "@/i18n";
import {asCurrency} from "@/filters/currency";
import RegisterAsBidderButton from "@/views/AuctionViewPage/RegisterAsBidderButton.vue";

export default {
  name: "AuctionRegisterAsBidder",
  components: {RegisterAsBidderButton},
  mixins:[auctionViewMixin],
  props: {
    block: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>
