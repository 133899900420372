import moment from "moment/moment";

export default {
  methods: {
    shouldShowRegisterAsBidderButton(auction) {
      let eventTime =
        auction.after_sale_end_date
          ? moment(auction.after_sale_end_date).toDate().getTime()
          : 0;
      return (auction.manual_bidder_approval || auction.initial_deposit_amount || auction.require_credit_card_verification || auction.type === 'live')
        && !auction.is_bidder && (auction.status === 'published' || (auction.status === 'completed' && eventTime > Date.now()))
    }
  }
}
