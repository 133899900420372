<template>
  <div v-if="contactInfo" class="ta-auction-contact-details">
    <slot name="icon">
      <b-icon-envelope class="mr-1" />
    </slot>
    <b v-if="label" class="ta-auction-contact-details-label">{{ label }}: </b>
    <span class="ta-auction-contact-details-value">{{ contactInfo}}</span>
  </div>
</template>

<script>
import CountDownTimer from "@/components/CountDownTimer.vue";
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionContactDetails",
  mixins: [auctionViewMixin],
  props: {
    label: {
      type: [Boolean, String],
      default() {
        return this.$t('Contact Details')
      }
    },
  },
  components: {CountDownTimer}
}
</script>

<style scoped>

</style>
