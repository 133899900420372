<script>
import {mapActions, mapState, mapGetters, mapMutations} from "vuex";

import LotListAndFilters from '../../components/LotListAndFilters'

import PageComponent from "../../components/core/PageComponent";
import AuctionInfo from './AuctionInfo.vue'
import templatable from "@/mixins/templatable";
import AuctionSessions from "@/views/AuctionViewPage/AuctionSessions.vue";

export default {
  name: "AuctionViewPage",
  components: {
    PageComponent,
    AuctionInfo,
    AuctionSessions,
    LotListAndFilters
  },
  mixins: [templatable('auctionViewPageTemplate')],
  data() {
    return {
      auctionData: null,
      template: `
        <page-component class="ta-auction-view">
          <div v-if="auctionObject.loading" class="ta-auction-view-loader">
            <b-spinner label="Spinning"></b-spinner>
            <span class="mt-2">{{ $t("Loading...") }}</span>
          </div>
          <template v-else>
            [[template]]
          </template>
        </page-component>
      `
    };
  },

  computed: {
    ...mapState(["auctionObject", "mainConfig"]),
    ...mapGetters(["currentLocale", "is_authorized"]),
    auction() {
      return this.auctionObject.data;
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.setLotFilters({
          ...this.$route.query,
          auction_uuid: this.$route.params.uuid
        });
        this.getLots();
        this.getDepartments();
      },
    },
  },
  methods: {
    ...mapActions(["getAuctionData", "registerAsBidder", "getLots", "getDepartments"]),
    ...mapMutations(['setLotFilters', 'emptyLots'])
  },
  beforeMount() {
    this.getAuctionData(this.$route.params.uuid);
  },
  beforeDestroy() {
    this.emptyLots();
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    // setTimeout(() => {
    //   const html = document.querySelector('html')
    //   html.style.scrollBehavior = 'smooth';
    //   html.scrollTop = 0;
    //   setTimeout(() => html.style.scrollBehavior = '', 1000)
    // }, 1000)
    return this.templateRender();
  },
};
</script>

<style scoped lang="scss">
.ta-auction-view-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
