import {mapState} from "vuex";

export default {
  name: "LotListItems",
  computed: {
    ...mapState(['lotQueryParams']),
  },
  data: () => ({
    timeoutId: null,
    skeletonNumbers: []
  }),
  methods: {
    onScrollChange() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        window.localStorage.setItem('LotListItems.scrollPosition', window.scrollY)
      }, 200);
    }
  },
  mounted() {
    const scroll = window.localStorage.getItem('LotListItems.scroll')

    if (scroll) {
      window.localStorage.removeItem('LotListItems.scroll')
      const scrollY = parseInt(window.localStorage.getItem('LotListItems.scrollPosition'))
      if (scrollY) {
        setTimeout(() => {
          window.scrollTo(0, scrollY)
          setTimeout(() => {
            window.addEventListener('scroll', this.onScrollChange)
          }, 500)
        }, 200)
      }
    } else {
      window.addEventListener('scroll', this.onScrollChange)
    }

    for (let i = 0; i < this.lotQueryParams.limit; i++) {
      this.skeletonNumbers.push(i)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScrollChange)
  }
}
