<template>
  <div class="ta-auction-view-title">
    <h1>{{ auction.title | currentLocale }}</h1>
  </div>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionTitle",
  mixins: [auctionViewMixin],
}
</script>

<style scoped>

</style>
