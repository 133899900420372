<template>
  <div>
    <!--    <div v-if="auction.start_date && auction.end_date" class="mb-3">-->
    <!--      <auction-dates :auction="auction"/>-->
    <!--    </div>-->

    <div class="row">
      <div class="col-md-6">
        <table class="text-sm ta-auction-dates mb-2">
          <tbody>
          <tr v-if="auction.premium_terms.length === 1">
            <td class="pr-2 font-weight-bold">
              {{ $t(`Buyer's Premium`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ auction.premium_terms[0].percent }}%
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <register-as-bidder-button :auction="auction" />

    <auction-stream-bid-button :block="false" :auction="auction" />

  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import AuctionDates from "./AuctionDates.vue";
import LinkButton from "@/components/LinkButton";
import AuctionIncrementsLink from "@/views/AuctionViewPage/AuctionIncrementsLink";
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";
import AuctionBuyersPremiumLink from "@/views/AuctionViewPage/AuctionBuyersPremiumLink";
import AuctionCatalogLink from "@/views/AuctionViewPage/AuctionCatalogLink";
import AuctionTermsLink from "@/views/AuctionViewPage/AuctionTermsLink";
import RegisterAsBidderButton from "@/views/AuctionViewPage/RegisterAsBidderButton.vue";
import AuctionStreamBidButton from "@/views/AuctionViewPage/AuctionStreamBidButton.vue";

export default {
  components: {
    AuctionStreamBidButton,
    RegisterAsBidderButton,
    AuctionTermsLink,
    AuctionCatalogLink,
    AuctionBuyersPremiumLink,
    AuctionIncrementsLink,
    LinkButton,
    AuctionDates,
  },
  mixins: [auctionViewMixin],
  name: "AuctionDetails",
  computed: {
    ...mapGetters(['currentLocale']),
    locationInfo() {
      return this.$options.filters.currentLocale(this.auction.auction_location_info)
    },
    contactInfo() {
      return this.$options.filters.currentLocale(this.auction.contact_info)
    },
    viewingInfo() {
      return this.$options.filters.currentLocale(this.auction.viewing_info)
    }
  }
};
</script>

<style lang="scss">
</style>
