import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['mainConfig', 'is_authorized']),
    biddingStarted() {
      const currentTime = Date.now();

      return this.lotStartDate < currentTime;
    },
    biddingFinished() {
      const currentTime = Date.now();
      let finished = this.lotEndDate < currentTime;
      // console.log(this.lotStartDate.toString(), this.lotEndDate.toString());
      if (this.lot.status === 'sold' || this.lot.status === 'unsold') {
        finished = true;
      }
      return finished;
    },
    lotStartDate() {
      return this.lot.actual_start_date;
    },
    lotEndDate() {
      return this.lot.actual_end_date;
    },
    isLotUnsold() {
      return this.lot.status === 'unsold'
    },
    isLotSold() {
      return this.lot.status === 'sold'
    },
    isAfterSale() {
      if (!this.lot.buy_now_price || !this.isLotUnsold || this.lot.auction.status !== 'completed' || !this.lot.auction.after_sale_end_date) {
        return false;
      }
      const afterSaleEndDate =
        typeof this.lot.auction.after_sale_end_date === "string"
          ? moment(this.lot.auction.after_sale_end_date).toDate().getTime()
          : this.lot.auction.after_sale_end_date.getTime();

      return new Date().getTime() < afterSaleEndDate;
    },
    buyNowDisabled() {
      return !this.is_authorized || !this.lot.auction.is_bidder ||
        ((this.lot.winning_bid && this.lot.buy_now_price && this.lot.winning_bid.amount >= this.lot.buy_now_price) && !this.isAfterSale)
    },
    showBuyNow() {
      return this.showEmpty || this.lot.buy_now_price &&
        (this.biddingFinished && this.isAfterSale || (this.mainConfig.enableBuyNowOnPublishedAuction && ( this.lot.auction.type === 'timed' && this.biddingStarted)))
    }
  }
}
