<template>
  <div class="ta-auction-currency">
    <b class="ta-auction-currency-label">{{ $t("Currency") }}:</b>
    <span class="ta-auction-currency-value">{{ auction.currency.display }} ({{auction.currency.symbol}})</span>
  </div>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionCurrency",
  mixins: [auctionViewMixin],
}
</script>

<style scoped>
 .ta-auction-currency-label {
   margin-right: 0.25rem;
 }
</style>
