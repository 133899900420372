<script>

import templatable from "@/mixins/templatable";
import lotBiddingMixin from "@/mixins/lotBiddingMixin";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";
import StreamBidButton from "@/components/lot-view/sidebar/StreamBidButton.vue";
import BiddingLimit from "../lot-bidding/BiddingLimit.vue";
import WatchlistButton from "@/components/lot-view/sidebar/WatchlistButton.vue";
import BiddingActions from "@/components/configuration/lot-bidding/BiddingActions.vue";
import BiddingMaxBid from "@/components/configuration/lot-bidding/BiddingMaxBid.vue";
import LoginSignupButtons from "@/components/lot-view/sidebar/LoginSignupButtons.vue";
import BiddingAdditionalLinks from "@/components/configuration/lot-bidding/BiddingAdditionalLinks.vue";
import BiddingCommissionBidInput from "@/components/configuration/lot-bidding/BiddingCommissionBidInput.vue";
import BiddingLatestNotification from "@/components/configuration/lot-bidding/BiddingLatestNotification.vue";
import BiddingStartPrice from "@/components/configuration/lot-bidding/BiddingStartPrice.vue";
import BiddingHighestCommissionBid from "@/components/configuration/lot-bidding/BiddingHighestCommissionBid.vue";
import BiddingBuyNowButton from "@/components/configuration/lot-bidding/BiddingBuyNowButton.vue";
import BiddingCurrentPrice from "../lot-bidding/BiddingCurrentPrice";
import {mapMutations} from "vuex";

export default {
  name: "LotViewBiddingLive",
  components: {
    StreamBidButton,
    BiddingLimit,
    BiddingCurrentPrice,
    WatchlistButton,
    BiddingActions,
    BiddingMaxBid,
    BiddingCommissionBidInput,
    BiddingHighestCommissionBid,
    LoginSignupButtons,
    BiddingBuyNowButton,
    BiddingAdditionalLinks,
    BiddingLatestNotification,
    BiddingStartPrice
  },
  mixins: [lotBiddingMixin, templatable('lotBiddingTemplateLive'), dynamicFieldsMixin],
  data() {
    return {
      newBidAmount: null,
      interval: null,
      template: `<div id="lotViewBidding" class="ta-lot-bidding">
                      [[template]]
                  </div>
    `
    }
  },
  methods: {
    ...mapMutations(['setLotBiddingStartedFinishedFlags']),

    updateBiddingFlags() {
      const currentTime = Date.now();

      let started = this.lotStartDate < currentTime;
      let finished = this.lotEndDate < currentTime;
      // console.log(this.lotStartDate.toString(), this.lotEndDate.toString());
      if (this.lot.auction.status === 'completed') {
        finished = true;
      }

      this.setLotBiddingStartedFinishedFlags({started, finished})
    }
  },
  mounted() {
    this.newBidAmount = this.next;
    this.updateBiddingFlags();
    if (!this.biddingStarted || !this.biddingFinished) {
      this.interval = setInterval(() => {
        this.updateBiddingFlags();
        // if (this.biddingFinished) {
        //   clearInterval(this.interval);
        // }
      }, 1000);
    }
  },

  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss">
#artisioTimedAuctions {

  .ta-btn-watchlist {
    position: static !important;
  }
}
</style>

<style scoped lang="scss">
@import "../../../bootstrap/import";


.ta-lot-bidding-content {
  position: relative;
  background-color: $gray-100;
  padding: 1rem;
}
</style>
