<template>
  <b-button v-if="shouldShowRegisterAsBidderButton(auction)"
            class="ta-register-as-bidder-button"
            @click="onClick"
            variant="primary"
            :block="block">
    <b-icon-person-plus-fill></b-icon-person-plus-fill>
    {{ $t('Register as Bidder') }}
  </b-button>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import i18n from "@/i18n";
import auctionBaseMixin from "@/mixins/auctionBaseMixin";

export default {
  name: "RegisterAsBidderButton",
  mixins: [auctionBaseMixin],
  props: {
    auction: {
      type: Object
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['is_authorized']),
  },
  methods: {
    ...mapActions(['registerAsBidder']),
    ...mapMutations(['showNotification', 'showModalMsgBox']),
    async onClick() {
      try {
        const agreed = await this.$confirm(this.$t('Are you sure you want to register on this auction as a bidder?'))
        if (agreed) {
          const res = await this.registerAsBidder({auction: this.auction})
          if (!res.success) {
            if (res.error_code === 'DEPOSIT_REQUIRED') {
              const result = await this.$confirm(this.$t(res.error, {
                ...res.params,
                deposit_amount: this.$options.filters.asCurrency(res.params.deposit_amount, this.auction.currency.code)
              }))
              if (result) {
                const res = await this.registerAsBidder({auction: this.auction, force: true})
                if (res.success) {
                  this.showSuccess(i18n.t('You have successfully registered as bidder. Please check your email for farther instructions.'))
                } else {
                  this.showError(i18n.t(res.message || 'Something went wrong. Contact website support.'))
                }
              }
            } else if(res.error_code === 'CREDIT_CARD_VERIFICATION_REQUIRED') {
              const result = await this.$confirm(`<p>${this.$t("Credit/Debit Card verification is required to participate on this auction.")}</p>
                <p>${this.$t("If you would like to proceed, please click the 'Proceed' button below, which will redirect you to the card verification page.")}</p>
                <div class="alert alert-danger"><strong>
                  ${this.$t("Please note:")}
                  </strong><br>
                  ${this.$t("During the verification process, nominal amount of {amount} will be deducted from your card and immediately refunded.", {
                      ...res.params,
                      amount: this.$options.filters.asCurrency(
                        1.0,
                        this.auction.currency.code
                      ),
                    }
                  )}</div>`, null, null, null, i18n.t('Please Confirm'), i18n.t('Proceed'), i18n.t('Cancel')
              )
              if (result) {
                return window.location.href = res.payment_url;
              }
            } else {
              this.showError(i18n.t(res.error || 'Something went wrong. Contact website support.'))
            }
          } else {
            if (!res.status) {
              this.showSuccess(i18n.t('You have successfully registered as bidder.'))
              return
            }
            if (res.status === 'pending') {
              this.showWarning(i18n.t('Your bidder registration status is pending, you will be notified once you have been approved.'))
            } else if (res.status === 'rejected') {
              this.showError(i18n.t('You have been rejected from participating on this auction. Please contact our support if you have any questions.'))
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    showSuccess(message) {
      // show notification
      this.showNotification({
        title: i18n.t("Success!"),
        text: message,
        variant: "success",
      });
    },
    showWarning(message) {
      // show notification
      this.showModalMsgBox({
        title: i18n.t('Your status is pending'),
        content: i18n.t(message)
      });
    },
    showError(content) {
      this.showModalMsgBox({
        title: i18n.t('You have been rejected'),
        content: i18n.t(content)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions .ta-register-as-bidder-button {
  margin-bottom: 1rem;
}
</style>
