<template>
  <div class="ta-auction-viewing-info" v-if="viewingInfo">
    <slot name="icon">
      <b-icon-eye-fill class="mr-1" />
    </slot>
    <b v-if="label" class="ta-auction-viewing-info-label">{{ label }}: </b>
    <span class="ta-auction-viewing-info-value">{{ viewingInfo }}</span>
  </div>
</template>

<script>

import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionViewingInfo",
  mixins: [auctionViewMixin],
  props: {
    label: {
      type: [Boolean, String],
      default() {
        return this.$t('Viewing Information')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
